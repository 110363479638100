@use '@/styles/utils/mixins.scss' as *;

.contactFormWrapper {
  .contactForm {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  .contactFormField {
    display: flex;
    flex-direction: column;

    > :not(:nth-last-child(-n + 2)) {
      margin-bottom: 2.5rem;
    }

    > :nth-last-child(-n + 2) {
      margin-bottom: 1rem;
    }
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .float-label {
      .as-label,
      .ant-select-selector,
      .float-input {
        background: $neutral02 !important;
      }
    }
  }
}

.formSubmit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .submitContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 25rem;
  }

  .submitLogo {
    margin-bottom: 5rem;

    @include tab() {
      margin-bottom: 3.75rem;
    }

    img {
      @include mob() {
        height: 6.25rem;
        width: 6.25rem;
      }
    }
  }

  .submitHeading {
    margin-bottom: 0.5rem;
  }

  .submitMessage {
    text-align: center;

    p {
      font-size: 1rem;
      font-weight: 400;
    }
  }
}
